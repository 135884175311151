html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: MyriadVariable Arial -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #cfe5bf;
  background-image: url('./assets/images/bg.png');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 769px) {
  body {
    overflow-x: hidden;
  }
}
